import { useRef, useEffect, useState } from "react";

/**
 * Used to measure height of HTML elements
 * For properties available as a part of resize observer: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
 * @returns Object contains ref that can spread on element or destructured individually.
 * @example
 * const {ref, bounds} = useMeasure();
 * <element {...bind} />
 */
function useMeasure() {
  const ref = useRef<HTMLDivElement>(null);

  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });

  const [ro] = useState(
    () =>
      new ResizeObserver((entries) => {
        // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return null;
          }
          set(entries[0].contentRect);
          return null;
        });
      })
  );
  useEffect(() => {
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
    // will display old data if ref.current is not a dependency
    // eslint-disable-next-line
  }, [ro, ref.current]);

  return { ref, bounds };
}

export default useMeasure;
