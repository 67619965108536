import Metric from "./Metric";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { getUnit } from "./metricUtils";
import { Maybe } from "constants/types";

dayjs.extend(relativeTime);
dayjs.extend(duration);

type MetericsProps = {
  approvalCount: Maybe<number>;
  weekResponseTime: Maybe<number>;
  monthResponseTime: Maybe<number>;
  avgDiscount: Maybe<number>;
  loading: boolean;
};

const Metrics: React.FC<MetericsProps> = ({
  approvalCount,
  weekResponseTime,
  monthResponseTime,
  avgDiscount,
  loading,
}) => {
  return (
    <div className="flex justify-center gap-x-16">
      <Metric
        metric={approvalCount || 0}
        description="Approvals Pending Review"
        loading={loading}
      />
      <Metric
        metric={
          weekResponseTime
            ? dayjs
                .duration(weekResponseTime, "seconds")
                .as(getUnit(weekResponseTime))
            : undefined
        }
        label={getUnit(weekResponseTime)}
        description="Avg Response Time (Past 7 Days)"
        loading={loading}
      />
      <Metric
        metric={
          monthResponseTime
            ? dayjs
                .duration(monthResponseTime, "seconds")
                .as(getUnit(monthResponseTime))
            : undefined
        }
        label={getUnit(monthResponseTime)}
        description="Avg Response Time (Past 30 Days)"
        loading={loading}
      />
      <Metric
        metric={avgDiscount}
        symbol="%"
        description="Avg Discount"
        loading={loading}
      />
    </div>
  );
};

export default Metrics;
