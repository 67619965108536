import { FlowInstance, Maybe } from "constants/types";
import dayjs from "dayjs";
import Link from "next/link";
import UserCell from "components/People/UserCell";
import PaginationTable from "components/Table/PaginationTable";
import Image from "next/image";
import ApprovalPathMinimal from "components/People/ApprovalPathMinimal";
import { IS_TABLET_OR_SMALLER_MEDIA_QUERY } from "constants/screen";
import useMatchMedia from "hooks/useMatchMedia";

export type QueueTableProps = {
  loading: boolean;
  requests: Array<Maybe<FlowInstance>>;
  pageCount: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  className?: string;
};

const QueueTable: React.FC<QueueTableProps> = ({
  loading,
  requests,
  pageCount,
  currentPage,
  setCurrentPage,
  className,
}) => {
  const isTabletOrSmaller = useMatchMedia(IS_TABLET_OR_SMALLER_MEDIA_QUERY);

  const isHidden = () => isTabletOrSmaller;

  const columns = [
    {
      id: "name",
      header: "Request ID",
      getCell: (row: FlowInstance) => (
        <Link href={`/requests/${row.id}`} passHref>
          <a className="hover:underline">{row.name}</a>
        </Link>
      ),
    },
    {
      id: "initiator",
      header: "Initiator",
      getCell: (row: FlowInstance) => {
        return <UserCell user={row.initiator} />;
      },
    },
    {
      id: "responseTime",
      header: "Response Time",
      getCell: (row: FlowInstance) => {
        return (
          <span title={dayjs(row.currentStepInstance?.activatedAt).format()}>
            {dayjs(row.currentStepInstance?.activatedAt).fromNow(true)}
          </span>
        );
      },
    },
    {
      id: "createdAt",
      header: "Initiated",
      isHidden,
      getCell: (row: FlowInstance) => {
        return (
          <span title={dayjs(row.createdAt).format()}>
            {dayjs(row.createdAt).fromNow()}
          </span>
        );
      },
    },
    {
      id: "approvalPath",
      header: "Approval Path",
      getCell: (row: FlowInstance) => {
        return (
          <div className="min-w-[300px]">
            <ApprovalPathMinimal flowInstance={row} />
          </div>
        );
      },
    },
  ];

  return (
    <PaginationTable<FlowInstance>
      loading={loading}
      columns={columns}
      data={requests || []}
      pageCount={pageCount}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      style={{
        gridTemplateColumns: `minmax(300px,1fr) minmax(300px,1fr) minmax(300px,1fr) minmax(300px,1fr) max-content`,
      }}
      className={className}
      emptyMessage={
        <>
          <div className="m-auto flex h-full  flex-grow flex-col justify-center">
            <p className="mb-24 text-center">You{`'`}re all caught up!</p>
            <Image
              src="/illustrations/empty-queue.svg"
              alt=""
              width={374}
              height={320}
            />
          </div>
        </>
      }
    />
  );
};

export default QueueTable;
