import { Maybe } from "constants/types";
import { round } from "lodash";

type MetricProps = {
  metric?: Maybe<number>;
  label?: string;
  symbol?: string;
  description?: string;
  loading: boolean;
};

const Metric: React.FC<MetricProps> = ({
  metric,
  label,
  description,
  symbol,
  loading,
}) => (
  <div className="w-[385px] rounded-xlarge bg-dark-blue-10 p-16 text-center font-medium">
    <p className="mb-8 text-t1 text-grey--50">
      {`${loading ? "" : (metric && round(metric, 1)) ?? "-"}${
        symbol && metric ? symbol : ""
      }`}
      <span className="ml-8 text-t3 text-grey-75">{metric ? label : ""}</span>
    </p>
    <p className="text-t4 text-grey-75">{description}</p>
  </div>
);

export default Metric;
