import Link from "next/link";
import { FC } from "react";
import { Maybe } from "constants/types";
import Avatar from "./Avatar";

interface UserCellProps {
  user?: Maybe<{
    id: string;
    name: string;
    title?: Maybe<string>;
    avatarUrl?: Maybe<string>;
  }>;
}

const UserCell: FC<UserCellProps> = ({ user }) => {
  if (!user) {
    return null;
  }
  return (
    <Link href={`/people/${user.id}`}>
      <a className="group flex h-full gap-x-8 text-t4">
        {user?.name && (
          <Avatar
            name={user.name}
            imageSize={32}
            className="inline"
            avatarUrl={user.avatarUrl}
            isRounded
          />
        )}
        <div className="flex h-full flex-col justify-center gap-y-0">
          <p className="group-hover:underline">{user.name}</p>
          {user.title && <p className="text-grey-100">{user.title}</p>}
        </div>
      </a>
    </Link>
  );
};

export default UserCell;
